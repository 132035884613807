/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { CustomerState } from '@/store/customer/types';
const namespace: string = 'customer';

export default class ActivationPromoCodePage extends Vue {
	@State('customer') customer: CustomerState | undefined;
	@Action('postPromoCode', {namespace}) postPromoCode: any;
	@Getter('getRequestStatus', {namespace}) getRequestStatus: any;
	@Getter('getMessages', {namespace}) getMessages: any;

	inputPromocode: string = '';
	isValid: string = '';

	sendPromocode() {
		this.postPromoCode({
			code: this.inputPromocode
		});
		if(this.getRequestStatus) {
			this.isValid = 'is-valid';

		} else {
			this.isValid = 'is-invalid';
		}
	}
	mounted() {

	}
}
